import { pageViewAction, purchaseAction } from "./actions";

type PurchaseParams = {
  transactionId: string,
  revenue: string,
  list: string,
  product: {
    id: string,
    name: string,
    position: string,
    brand: string,
    category: string,
    variant?: string
    price: number
  }
}

const SegmentHub = {
  pageView: async function () {
    const location = window.location
    const searchParams = new URLSearchParams(location.search);
    const referrer = document.referrer;
    Object.defineProperty(document, "referrer", { value: "" }) // Delete referrer value after first page

    let session_id = sessionStorage.getItem("session_id");
    if (!session_id) {
      session_id = window.crypto.randomUUID();
      sessionStorage.setItem("session_id", session_id);
    }

    const payload = {
      session_id: session_id,
      page_title: document.title,
      page_hostname: location.hostname,
      page_path: location.pathname,
      page_location: location.href,
      user_agent: navigator.userAgent,
      page_referrer: referrer,
      ...(searchParams.get("utm_campaign") && { campaign: searchParams.get("utm_campaign") }),
      ...(searchParams.get("utm_source") && { source: searchParams.get("utm_source") }),
      ...(searchParams.get("utm_medium") && { medium: searchParams.get("utm_medium") }),
      ...(searchParams.get("utm_term") && { term: searchParams.get("utm_term") }),
      ...(searchParams.get("utm_content") && { content: searchParams.get("utm_content") }),
    };

    pageViewAction(payload);
  },

  purchase: async function (params: PurchaseParams) {

    const payload = {
      currency: "TRY",
      transaction_id: params.transactionId,
      value: params.revenue,
      items: [
        {
          item_id: params.product.id,
          item_name: params.product.name,
          currency: "TRY",
          index: params.product.position,
          item_brand: params.product.brand,
          item_category: params.product.category,
          item_list_name: params.list,
          ...(params.product.variant && { item_variant: params.product.variant }),
          price: params.product.price,
          quantity: 1
        }
      ]
    }

    purchaseAction(payload);
  }
};


export default SegmentHub;